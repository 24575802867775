body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #2D2D2D; /* Dark grey background */
}

body, html, canvas {
    margin: 0;
    padding: 0;
}


canvas {
    display: block;
}

#sceneContainer {
    position: relative;
    height: 400vh; /* This is an example; adjust accordingly to the desired scroll length. */
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

#buttonsContainer {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
}

button {
    position: relative;
    z-index: 2;
    margin: 10px;
}

.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: white;
    z-index: 10000; /* Ensure it's on top of other elements */
    transition: height 0.5s; /* Adjust the timing as needed */
}

.enter-button {
    border: 2px solid #f0f0f0;
    background: transparent;
    color: #f0f0f0;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'Gothic', sans-serif; /* You can use a font that feels old-world or gothic */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    outline: none;
    position: relative;
    top: 50%;
    left: 50%;
}

.enter-button:hover {
    transform: translateY(-2px);
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.9);
}

.enter-button:active {
    transform: translateY(1px);
}

.shadowBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center, transparent, rgba(0,0,0,0.5));
    opacity: 0.7;
    pointer-events: none;
    z-index: -1;
    background-image: url('https://cdn.discordapp.com/attachments/852542942081187853/1149871028869283910/7-noise-texture-overlays-4.png');
    animation: noiseAnimation 5s infinite linear;
}

@keyframes noiseAnimation {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 500% 500%;
    }
}

#popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 400px;
}

button {
    margin: 10px;
}

.popup-hidden {
    display: none;
}

#welcomeButton {
    z-index: 2;
    background: linear-gradient(135deg, #2C3E50, #34495E); /* Dark blue gradient background */
    border: none; /* No border */
    padding: 10px 20px; /* Button padding */
    font-size: 16px; /* Text size */
    color: white; /* Dark text for contrast against white background */
    cursor: pointer; /* Hand cursor on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: absolute;
    bottom: 2.5%;
    right: 22%;
}

#welcomeButton:hover {
    transform: translateY(-2px); /* Slight upward movement on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover for a lifting effect */
}

#welcomeButton:active {
    transform: translateY(1px); /* Button presses down when active/clicked */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow when button is active */
}


#aboutButton {
    z-index: 2;
    background: linear-gradient(135deg, #2C3E50, #34495E); /* Dark blue gradient background */
    border: none; /* No border */
    padding: 10px 33px; /* Button padding */
    font-size: 16px; /* Text size */
    color: white; /* Dark text for contrast against white background */
    cursor: pointer; /* Hand cursor on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: absolute;
    bottom: 2.5%;
    right: 16%;
}

#aboutButton:hover {
    transform: translateY(-2px); /* Slight upward movement on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover for a lifting effect */
}

#aboutButton:active {
    transform: translateY(1px); /* Button presses down when active/clicked */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow when button is active */
}

#projectsButton {
    z-index: 2;
    background: linear-gradient(135deg, #2C3E50, #34495E); /* Dark blue gradient background */
    border: none; /* No border */
    padding: 10px 25px; /* Button padding */
    font-size: 16px; /* Text size */
    color: white; /* Dark text for contrast against white background */
    cursor: pointer; /* Hand cursor on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: absolute;
    bottom: 2.5%;
    right: 10%;
}

#projectsButton:hover {
    transform: translateY(-2px); /* Slight upward movement on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover for a lifting effect */
}

#projectsButton:active {
    transform: translateY(1px); /* Button presses down when active/clicked */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow when button is active */
}


#contactButton {
    z-index: 2;
    background: linear-gradient(135deg, #2C3E50, #34495E); /* Dark blue gradient background */
    border: none; /* No border */
    padding: 10px 25px; /* Button padding */
    font-size: 16px; /* Text size */
    color: white; /* Dark text for contrast against white background */
    cursor: pointer; /* Hand cursor on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    position: absolute;
    bottom: 2.5%;
    right: 4%;
}

#contactButton:hover {
    transform: translateY(-2px); /* Slight upward movement on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1); /* Increased shadow on hover for a lifting effect */
}

#contactButton:active {
    transform: translateY(1px); /* Button presses down when active/clicked */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow when button is active */
}


#projects {
    transition: right 0.3s ease;
    position: fixed;
    right: -650px;
    top: 0;  /* Start at the top */
    width: 600px;
    height: 100vh;  /* Full viewport height */
    background: #fffdfd;  /* No transparency */
    transition: right 0.5s;
    overflow-y: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 30px 40px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

#projects h2 {
    color: #333;
    font-size: 28px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
}

.project {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s;
}

.project:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.08);
}

.project-content, .project-description {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
}

.project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project img {
    width: 100%;
    height: 85%;
    object-fit: cover;
}

.project h3 {
    margin-top: 8px;
    color: #666;
    font-size: 18px;
}

.project-description {
    transform: translateX(100%);
    display: flex;
    align-items: flex-start;  /* Align to start/top vertically */
    justify-content: flex-start;  /* Align to start/left horizontally */
    color: black;
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
    width: 100%;  /* Ensure it takes up full width */
    height: auto;  /* Let the content determine the height */
}




.view-project {
    position: absolute;
    bottom: -16%;  /* adjust this value if you want more/less spacing from the bottom */
    left: 50%;
    transform: translateX(-50%);  /* this will ensure the button is centered */
    text-align: center;
}


.view-project a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.view-project a:hover {
    background-color: #333;
}


.bubble {
    position: fixed;
    bottom: -600px; /* Start even further below the viewport */
    left: 0;
    width: var(--size, 250px);
    height: var(--size, 250px);
    background-color: black;
    border-radius: 50%;
    animation: riseBubble 1.5s forwards; /* Reduced duration */
    opacity: 1;
    pointer-events: none;
}

@keyframes riseBubble {
    0% {
        bottom: -600px;
    }
    100% {
        bottom: 110vh;
    }
}

.blackScreen {
    position: fixed;
    bottom: -100vh;  /* start from off-screen */
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #8B0000;
    z-index: 9999; /* Ensure it's on top */
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); /* subtle shadow for depth */
    transition: bottom 1s ease-in-out; /* smooth transition */
}

.redScreen {
    position: fixed;
    bottom: -100vh;  /* start from off-screen */
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #FFFDD0;
    z-index: 9999; /* Ensure it's on top */
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); /* subtle shadow for depth */
    transition: bottom 1s ease-in-out; /* smooth transition */
}

.yellowScreen {
    position: fixed;
    bottom: -100vh;  /* start from off-screen */
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #212E52;
    z-index: 9999; /* Ensure it's on top */
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5); /* subtle shadow for depth */
    transition: bottom 1s ease-in-out; /* smooth transition */
}

#aboutPanel {
    transition: left 0.3s ease;
    position: fixed;
    left: -650px;  /* Start hidden on the left side */
    top: 0;  
    width: 600px;
    height: 100vh;  
    background: #8B0000;  
    transition: left 0.5s;  /* Slide in from the left */
    overflow-y: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 50px 60px;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.1);  /* Adjusted shadow direction for left side */
}

#aboutPanel h2 {
    color: white;
    font-size: 28px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
}

#aboutPanel p {
    color: white; /* Change this to any color you prefer */
}

#contactPanel {
    position: fixed;
    right: -20px;
    top: 0;  /* Start at the top */
    width: 580px;
    height: 100vh;  /* Full viewport height */
    background: #212E52;  /* No transparency */
    transition: right 0.5s;
    overflow-y: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 50px 60px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

#contactPanel h2 {
    color: white;
    font-size: 28px; /* If you want to adjust the size, you can do so here */
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee; /* This creates the line separating the title and text */
    padding-bottom: 8px;
}

#contactPanel p {
    color: white; /* Change this to any color you prefer */
}

#contactPanel a {
    color: #3498db; /* Change this to your preferred color for links */
    text-decoration: none; /* This will remove the underline */
}

#contactPanel a:hover {
    color: #2c7fb2; /* A slightly darker shade of blue for hover effect */
    text-decoration: underline; /* This will underline the link when hovered */
}

.progress {
    background: rgba(255,255,255,0.1);
    justify-content: flex-start;
    border-radius: 100px;
    align-items: center;
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 40px;
    width: 500px;
}
  
.progress-value {
    box-shadow: 0 10px 40px -10px #fff;
    border-radius: 100px;
    background: #fff;
    height: 30px;
    width: 0;
    transition: width 0.3s ease;  /* This will animate the width changes smoothly over 0.3 seconds */
}

#enterScene {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2D2D2D;
    display: none; /* Initially hidden */
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Make sure it's above other elements */
    transition: opacity 0.5s ease-out;

}

#enterScene h1 {
    color: white;
    font-size: 2rem;
    text-align: center;
    animation: fadeIn 2s forwards;
    text-shadow: 0 0 3px #ffffff, 0 0 6px #ffffff, 0 0 9px #ffffff;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
